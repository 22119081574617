<script lang="ts">
	// import { goto } from '$app/navigation';
	export let imgSrc: string = '/static/assets/wads.png';

	// export let path: string | URL = '';
	export let size: string = '2.5rem';
	export let margin: string = '0 1rem 0 0';
</script>

<div
	role="link"
	tabindex="0"
	class="profile-image"
	style:background-image="url('{imgSrc}')"
	style:width={size}
	style:height={size}
	style:margin
/>

<style>
	.profile-image {
		background-color: var(--accent-color, var(--purewhite, black));
		background-position: center;
		background-size: cover;
		display: flex;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		position: relative;
	}
</style>
