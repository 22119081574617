<!--
  @component # Hero
  
  @summary **Heros** are bold, striking section callouts.
  
  @example <Hero title="Hero Title" />
  
  @description A **Hero** is a large responsive introductory area meant to lead the viewer into the page content. It has a dedicated space for page information, an optional call to action, and an optional background media element. 

  ## Usage examples
  
  @example Typical Hero
  <Hero prehead="A typical" title="Page hero" subtitle="With a tagline" description="And a longer description area for when more context is necessary." cta="Learn more" ctaAction="ctaActionFunction()"
  />
  // A typical Hero consists of a prehead, title, subtitle, description and a call to action.
  
  @example Slot Hero
  <Hero>
    <h1>Slot Hero</h1>
  </Hero>
  // A Hero can also accept custom information that will live within the Hero's card area.

  @example Positioned Card Hero
  <Hero title="Image Hero" cardPosition="center" cardVerticalPosition="center" />
  // A Hero's card can be positioned horizontally and vertically.

  @example Image Hero
  <Hero title="Image Hero" heroImage="imgurl.png" />
  // A Hero can have a supporting image in one of the supported image types: "jpeg", "jpg", "png", or "svg".

  @example Tall Hero
  <Hero title="Tall Hero" tall/>
  // In some instances, a taller version of the hero unit is desired. This provides a stronger impact on the initial information.

  @example Background Color Hero
  <Hero title="Background Color Hero" backgroundColor="var(--black)" />
  // A Hero can have a background color. Please provide an appropriate background color in line with the Gina Design System.

  @example Background Image Hero
  <Hero title="Background Image Hero" backgroundImage="imgurl.png" />
  // A Hero can have a background image in one of the supported image types: "jpeg", "jpg", "png", or "svg". A background image will override a background color.

  -->
<script>import { Button } from "@gn-as/web-components";
export let title = "";
export let prehead = "";
export let subtitle = "";
export let description = "";
export let cta = "";
export let ctaAction = "";
export let heroImage = "";
export let backgroundColor = "transparent";
export let backgroundImage = "";
export let backgroundPosition = "center center";
export let cardSize = "medium";
export let cardPosition = "left";
export let cardVerticalPosition = "center";
export let cardColor = "transparent";
export let fontColor = "var(--black)";
export let tall = false;
export let fade = false;
const SUPPORTED_IMAGE_TYPES = ["jpeg", "jpg", "png", "svg"];
const backgroundImageExtension = backgroundImage && backgroundImage.match(/\.(\w+)$/)[1];
if (backgroundImageExtension && SUPPORTED_IMAGE_TYPES.indexOf(backgroundImageExtension) === -1) {
  throw new Error(
    `Unsupported background image type. Must be one of: ${SUPPORTED_IMAGE_TYPES.join(
      ", "
    )}.`
  );
}
</script>

<section class="hero {cardVerticalPosition}" class:tall="{tall}">
  {#if backgroundImage || backgroundColor}
    <div class="background" class:fade="{fade}">
      {#if backgroundImage}
        <div
          style:background-image="url({backgroundImage})"
          style:background-size="cover"
          style:background-position="{backgroundPosition}"
          style:min-height="{tall ? 50 : 33}rem"
          style:max-height="{tall ? 50 : 33}rem"
          title="background"
        ></div>
      {:else if backgroundColor}
        <div
          style:background="{backgroundColor}"
          style:min-height="33rem"
        ></div>
      {/if}
    </div>
  {/if}
  <div class="max-width {cardPosition}" class:heroImage="{heroImage}">
    <div
      class="card {cardSize}"
      style="background-color: {cardColor}; color: {fontColor}"
      lang="en"
    >
      {#if prehead}
        <h1 class="prehead">{prehead}</h1>
      {/if}
      {#if title}
        <h1 class="hero-text">{title}</h1>
      {/if}
      {#if subtitle}
        <h2 style:margin-top="0">{subtitle}</h2>
      {/if}
      {#if description}
        <p class="lead">{description}</p>
      {/if}
      {#if cta}
        <Button on:click="{ctaAction}">{cta}</Button>
      {/if}

      {#if !title && !prehead}
        <slot>
          <p>Please add a title or add markup inside the Hero component.</p>
        </slot>
      {/if}
    </div>

    {#if heroImage}
      <img src="{heroImage}" alt="{title} hero" />
    {/if}
  </div>
</section>

<style>
  .background {
    position: absolute;
    top: 0;
    min-width: 135rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }
  .background.fade {
    -webkit-animation: fade-in-fwd 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95)
      both 0.6s;
    animation: fade-in-fwd 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) both 0.6s;
  }
  :global(.hero .background img) {
    width: 100%;
  }
  .hero .background div {
    height: 100%;
    width: 100%;
  }
  .hero {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 33rem;
    width: 100%;
    padding: 8rem 4rem 4rem;
    max-width: 100vw;
    position: relative;
    overflow-x: hidden;
  }
  .hero.top {
    justify-content: flex-start;
  }
  .hero.bottom {
    justify-content: flex-end;
  }
  .max-width {
    max-width: 62rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .max-width.right .card {
    align-self: flex-end;
  }
  .max-width.left .card {
    align-self: flex-start;
  }
  .max-width.bottom .card {
    justify-self: flex-end;
  }
  .hero .card {
    padding: 1rem 2rem;
    max-width: 42rem;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    word-break: normal;
    word-wrap: break-all;
    -webkit-hyphens: manual;
    -moz-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
  }
  .hero .card.full {
    max-width: 100%;
  }
  .hero .card.large {
    max-width: 50rem;
  }
  .hero .card.small {
    max-width: 24rem;
  }
  .hero-text {
    margin-bottom: 1rem;
  }
  .hero.tall {
    min-height: 50.2256rem;
  }
  .hero.tall .background {
    max-height: 50rem;
  }
  .hero.tall .card {
    top: 2rem;
  }
  .heroImage {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));
  }
  .heroImage img {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    .hero {
      padding: 5rem 1rem 1rem;
    }
    .hero-text {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .hero.tall {
      padding: 5rem 1rem 1rem;
    }
    .hero.tall .card {
      top: 0rem;
      padding: 1rem;
    }
    .hero .card {
      padding: 0.5rem;
    }
    .heroImage img {
      order: -1;
      margin-bottom: 1rem;
    }
  }
  @-webkit-keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }
</style>
