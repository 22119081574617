<script lang="ts">
	import Hero from '../hero/Hero.svelte';
</script>

<svelte:options customElement={{ tag: null }}  />

<footer>
	<Hero
		backgroundImage="/static/assets/Dotty_Curtains_flipped.svg"
		backgroundPosition="bottom center"
		cardVerticalPosition="center"
		cardSize="full"
		cardPosition="center"
		tall={false}
	>
		<p>
			A
			<svg
				width="191"
				height="16"
				viewBox="0 0 191 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_712_9)">
					<path
						d="M185.679 6.36761C188.267 6.36761 190.074 8.24711 190.074 10.9853C190.074 11.2466 190.056 11.5079 190.038 11.7489H183.388C183.554 13.2031 184.711 14.1793 186.274 14.1793C187.202 14.1487 188.107 13.8862 188.907 13.4157L189.632 15.036C188.501 15.61 187.255 15.9215 185.987 15.9474C183.044 15.9474 181.013 13.9747 181.013 11.1615C181.013 8.34837 182.919 6.36761 185.679 6.36761ZM183.388 10.315H187.779C187.708 9.01065 186.87 8.13167 185.679 8.13167C184.45 8.13167 183.536 9.01065 183.388 10.315Z"
						fill="#392F2D"
					/>
					<path
						d="M176.958 6.36761C178.204 6.37398 179.42 6.74273 180.46 7.42888L179.49 9.03091C178.853 8.55742 178.086 8.2909 177.292 8.26736C175.69 8.26736 174.554 9.45825 174.554 11.1534C174.554 12.8486 175.69 14.0395 177.292 14.0395C178.056 14.0395 178.783 13.7418 179.49 13.2577L180.46 14.878C179.42 15.5641 178.204 15.9329 176.958 15.9393C174.123 15.9393 172.134 13.9463 172.134 11.1534C172.134 8.36053 174.127 6.36761 176.958 6.36761Z"
						fill="#392F2D"
					/>
					<path
						d="M161.699 6.5904H163.599L163.897 7.91293C164.585 6.9266 165.719 6.36761 167.044 6.36761C169.13 6.36761 170.6 7.80154 170.6 9.84913V15.7226H168.217V10.2218C168.239 9.71041 168.045 9.21326 167.682 8.8519C167.32 8.49053 166.822 8.29825 166.311 8.32205C165.027 8.32205 164.083 9.29015 164.083 10.6876V15.7165H161.699V6.5904Z"
						fill="#392F2D"
					/>
					<path
						d="M155.577 6.36761C158.165 6.36761 159.972 8.24711 159.972 10.9853C159.972 11.2466 159.954 11.5079 159.936 11.7489H153.28C153.448 13.2031 154.603 14.1793 156.166 14.1793C157.094 14.1489 157.999 13.8864 158.799 13.4157L159.526 15.036C158.395 15.6097 157.149 15.9213 155.881 15.9474C152.938 15.9474 150.909 13.9747 150.909 11.1615C150.909 8.34837 152.815 6.36761 155.577 6.36761ZM153.28 10.315H157.675C157.602 9.01065 156.764 8.13167 155.577 8.13167C154.342 8.13167 153.43 9.01065 153.28 10.315Z"
						fill="#392F2D"
					/>
					<path
						d="M148.022 2.54989C148.604 2.54332 149.132 2.88848 149.359 3.42375C149.586 3.95902 149.467 4.57841 149.058 4.99188C148.649 5.40534 148.031 5.53102 147.494 5.31006C146.956 5.0891 146.605 4.56524 146.605 3.98381C146.589 3.60185 146.733 3.23068 147.002 2.95884C147.271 2.68701 147.64 2.53891 148.022 2.54989ZM146.831 6.60052H149.215V15.7266H146.831V6.60052Z"
						fill="#392F2D"
					/>
					<path
						d="M141.805 6.36761C143.05 6.37363 144.267 6.74241 145.307 7.42888L144.338 9.03091C143.701 8.55761 142.933 8.29114 142.139 8.26736C140.537 8.26736 139.401 9.45825 139.401 11.1534C139.401 12.8486 140.537 14.0395 142.139 14.0395C142.902 14.0395 143.63 13.7418 144.338 13.2577L145.307 14.878C144.267 15.5645 143.05 15.9332 141.805 15.9393C138.969 15.9393 136.98 13.9463 136.98 11.1534C136.98 8.36053 138.969 6.36761 141.805 6.36761Z"
						fill="#392F2D"
					/>
					<path
						d="M131.16 2.97723C132.623 3.00344 134.069 3.30008 135.425 3.85217L134.866 5.70128C133.751 5.22831 132.557 4.96929 131.346 4.93774C129.894 4.93774 128.962 5.56964 128.962 6.55799C128.962 9.23951 135.873 7.48761 135.873 12.2937C135.873 14.4729 133.955 15.9393 131.16 15.9393C129.538 15.9165 127.937 15.5723 126.449 14.9266L127.008 13.0815C128.46 13.6405 129.843 13.995 130.973 13.995C132.502 13.995 133.452 13.3428 133.452 12.2998C133.452 9.54331 126.524 11.2567 126.524 6.56407C126.524 4.42939 128.423 2.97723 131.16 2.97723Z"
						fill="#392F2D"
					/>
					<path
						d="M121.563 15.7165H119.663L119.366 14.5438C118.674 15.4542 117.584 15.9741 116.441 15.9392C114.011 15.9392 112.269 13.9666 112.269 11.1534C112.269 8.34025 114.001 6.36759 116.441 6.36759C117.498 6.33751 118.509 6.79815 119.179 7.61519V2.60455H121.563V15.7165ZM117.055 14.0597C118.341 14.0597 119.234 13.0896 119.234 11.7691V10.5397C119.234 9.19696 118.341 8.24708 117.055 8.24708C115.678 8.24708 114.689 9.44 114.689 11.1534C114.689 12.8668 115.678 14.0597 117.055 14.0597Z"
						fill="#392F2D"
					/>
					<path
						d="M106.681 6.36761C109.27 6.36761 111.076 8.24711 111.076 10.9853C111.076 11.2466 111.058 11.5079 111.04 11.7489H104.391C104.559 13.2031 105.713 14.1793 107.277 14.1793C108.204 14.1487 109.11 13.8862 109.91 13.4157L110.635 15.036C109.504 15.61 108.258 15.9215 106.989 15.9474C104.046 15.9474 102.017 13.9747 102.017 11.1615C102.017 8.34837 103.931 6.36761 106.681 6.36761ZM104.391 10.315H108.792C108.711 9.01065 107.872 8.13167 106.681 8.13167C105.452 8.13167 104.539 9.01065 104.391 10.315Z"
						fill="#392F2D"
					/>
					<path
						d="M97.8551 6.36761C99.1006 6.37398 100.317 6.74273 101.357 7.42888L100.387 9.03091C99.7497 8.55742 98.9826 8.2909 98.1892 8.26736C96.5872 8.26736 95.451 9.45825 95.451 11.1534C95.451 12.8486 96.5872 14.0395 98.1892 14.0395C98.9528 14.0395 99.6799 13.7418 100.387 13.2577L101.357 14.878C100.317 15.5641 99.1006 15.9329 97.8551 15.9393C95.0196 15.9393 93.0308 13.9463 93.0308 11.1534C93.0308 8.36053 95.0196 6.36761 97.8551 6.36761Z"
						fill="#392F2D"
					/>
					<path
						d="M82.5923 6.5904H84.488L84.7857 7.91293C85.4743 6.9266 86.6085 6.36761 87.933 6.36761C90.0191 6.36761 91.4895 7.80154 91.4895 9.84913V15.7226H89.1098V10.2218C89.1318 9.71151 88.9387 9.21539 88.5776 8.85423C88.2164 8.49307 87.7203 8.3 87.21 8.32205C85.926 8.32205 84.9822 9.29015 84.9822 10.6876V15.7165H82.5923V6.5904Z"
						fill="#392F2D"
					/>
					<path
						d="M75.8883 10.0355C76.4832 10.0353 77.0766 10.0977 77.6584 10.2218V9.73774C77.6584 8.8061 76.9131 8.13572 75.7769 8.13572C74.7447 8.16021 73.7266 8.38156 72.7774 8.78787L72.182 7.13116C73.4151 6.63923 74.7288 6.38034 76.0564 6.36761C78.3653 6.36761 79.8923 7.74483 79.8923 9.77622V13.4826C79.88 13.6556 79.9423 13.8257 80.0634 13.9499C80.1845 14.0741 80.353 14.1406 80.5263 14.1327C80.683 14.1304 80.8391 14.1121 80.9921 14.078L81.1602 15.8096C80.7426 15.8926 80.3181 15.936 79.8923 15.9393C78.9607 15.9393 78.2539 15.4734 77.9379 14.7099C77.1385 15.4979 76.061 15.9395 74.9384 15.9393C73.0387 15.9393 71.6797 14.7484 71.6797 13.0714C71.6797 11.1534 73.2432 10.0355 75.8883 10.0355ZM75.5541 14.2643C76.8199 14.2643 77.6584 13.3894 77.6584 12.1398V11.601C77.172 11.4733 76.6707 11.4106 76.1678 11.4147C74.8088 11.4147 73.9703 11.9919 73.9703 12.9418C73.9703 13.6871 74.6589 14.2643 75.5541 14.2643Z"
						fill="#392F2D"
					/>
					<path
						d="M67.5402 15.7165H65.3427L61.6182 6.59039H64.1134L66.4404 13.0167L68.7878 6.59039H71.2647L67.5402 15.7165Z"
						fill="#392F2D"
					/>
					<path
						d="M60.5891 15.7165H58.6894L58.3916 14.5438C57.6996 15.4542 56.6102 15.9741 55.4671 15.9392C53.0266 15.9392 51.2949 13.9666 51.2949 11.1534C51.2949 8.34025 53.0266 6.36759 55.4671 6.36759C56.5237 6.33751 57.5347 6.79815 58.2053 7.61519V2.60455H60.5891V15.7165ZM56.0807 14.0597C57.3668 14.0597 58.26 13.0896 58.26 11.7691V10.5397C58.26 9.19696 57.3668 8.24708 56.0807 8.24708C54.7035 8.24708 53.7152 9.44 53.7152 11.1534C53.7152 12.8668 54.7157 14.0597 56.0848 14.0597H56.0807Z"
						fill="#392F2D"
					/>
					<path
						d="M43.5765 3.20001H45.8672L50.9892 15.7165H48.496L47.3416 12.7372H42.1082L40.9436 15.7165H38.4565L43.5765 3.20001ZM42.7765 10.9306H46.6874L44.731 5.92001L42.7765 10.9306Z"
						fill="#392F2D"
					/>
					<path
						d="M32.7432 15.7205C32.3952 15.7208 32.0614 15.5823 31.8156 15.3357L21.3731 4.89116C21.3025 4.82095 21.1966 4.80002 21.1045 4.83807C21.0125 4.87613 20.9523 4.96573 20.9519 5.06534V14.3696C20.9611 15.0945 20.3902 15.6942 19.6658 15.7205C19.3149 15.7265 18.9764 15.5913 18.7262 15.3453C18.476 15.0993 18.3351 14.7631 18.3351 14.4122V1.35293C18.3275 0.890777 18.5567 0.456813 18.9427 0.202552C19.4601 -0.119712 20.1312 -0.0433696 20.563 0.386856L31.0197 10.8294C31.0905 10.8982 31.1955 10.9182 31.2867 10.8803C31.3778 10.8425 31.4377 10.7539 31.4389 10.6552V1.35698C31.4306 0.633 31.9977 0.0327338 32.721 2.07675e-05C33.0727 -0.00704805 33.4125 0.127604 33.6639 0.373679C33.9153 0.619754 34.0572 0.956582 34.0577 1.30838V14.3696C34.0656 14.8438 33.8232 15.287 33.4197 15.5362C33.2166 15.6561 32.9852 15.7197 32.7493 15.7205"
						fill="#EA7200"
					/>
					<path
						d="M14.4121 6.54992H10.5255C9.79729 6.53959 9.1948 7.11416 9.1706 7.84208C9.16462 8.19292 9.29983 8.53145 9.54584 8.78166C9.79186 9.03186 10.1281 9.17276 10.479 9.17271H12.7696C12.857 9.17217 12.9409 9.2065 13.0029 9.26809C13.0649 9.32968 13.0997 9.41344 13.0997 9.50081V12.7717C13.0986 12.9532 12.9511 13.0998 12.7696 13.0998H7.95744C6.0647 13.1503 4.29194 12.1758 3.3204 10.5507C2.34886 8.9255 2.3297 6.90262 3.27028 5.25936C4.21086 3.6161 5.96484 2.60815 7.85819 2.62283H14.3595C15.0873 2.63208 15.6891 2.05812 15.7144 1.33068C15.7204 0.98108 15.5859 0.643679 15.3412 0.393974C15.0964 0.14427 14.7618 0.00310851 14.4121 0.0020752H7.97566C3.76301 0.0020752 0.204524 3.27296 0.0080679 7.49575C-0.0920593 9.64198 0.690057 11.7358 2.17274 13.2907C3.65543 14.8457 5.70963 15.7266 7.85819 15.7287H14.4121C14.7613 15.7287 15.0961 15.59 15.343 15.3431C15.5899 15.0962 15.7286 14.7613 15.7286 14.4122V7.86435C15.7275 7.13809 15.1384 6.54992 14.4121 6.54992Z"
						fill="#EA7200"
					/>
				</g>
				<defs>
					<clipPath id="clip0_712_9">
						<rect width="190.177" height="16" fill="white" />
					</clipPath>
				</defs>
			</svg>
			Application
		</p>

		<div class="info">
			<div>
				Visit <a
					href="https://gitlab.com/gn-as/ecosystem/rce/platform/rr/dtp"
					>GitLab</a
				> for more information
			</div>
		</div>
	</Hero>
</footer>

<style>

	footer {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-shrink: 0;
	}
	.info {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
	}
</style>
