<script lang="ts">
	import ProfilePic from './ProfilePic.svelte';
	import { fly } from 'svelte/transition';
	import MediaQuery from '../helpers/MediaQuery.svelte';


	type UserInfo = {
		name: string;
		email: string;
		pictureUrl: string;
		isSuperuser: string;
		isStaff: string;
	};
	export let user: UserInfo 
	
	export let open = false;
	

	function openAndBlockPropagation (event) {
		// this is a workaround to make the header work as expected when exported as a web component
		open = !open
		event.stopPropagation()
	}
	
	function blockPropagation (event) {
		// stop events from bubling up to avoid the menu from closing when clicked
		event.stopPropagation()
	}
		
</script>

<!-- The button in the header -->
<div id=clickoutsidetarget>
	<div class="profile-button">
		<div
			class="profile-image"
			class:open
			role="button"
			tabindex="0"
			on:click={openAndBlockPropagation}
			on:keydown={() => (open = !open)}
			style:background-image="url({user.pictureUrl})"
		>
			<span>Profile</span>
		</div>
	</div>
	<!-- The menu itself -->
	<MediaQuery query="(max-width: 768px)" let:matches>
		{#if open}
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<div
				class="menu"
				in:fly={{ y: -32, duration: 400 }}
				out:fly={{ y: -32, duration: 200 }}
				style:margin-right={matches ? '-3rem' : '0'}
				on:click={blockPropagation}
				role="button"
				tabindex="0"
			>
				<div class="profile" in:fly={{ x: -16, duration: 400, delay: 200 }}>
					<ProfilePic imgSrc={user.pictureUrl} />
					<div class="profile-info">
						<div class="name">{user.name}</div>
						<div class="email text-muted" style:font-size="0.8rem">
							{user.email}
						</div>
						<div class="actions" style:justify-self="flex-end">
							{#if user.isStaff === "True"} 
								<a href="/admin" class="link">Admin</a>
							{/if}
							<a href="/log-out" class="link">Log out</a>
						</div>
					</div>
				</div>
			</div>
		{/if}
	</MediaQuery>
</div>

<style>
	.menu {
		position: absolute;
		top: 3.5rem;
		right: 1rem;
		background: var(--purewhite);
		padding: 1rem;
		box-shadow: 0 0 1rem 0 var(--black-a10);
		width: 21.5rem;
		max-width: calc(100vw - 2rem);
	}
	.menu .profile {
		display: flex;
	}
	.menu .profile-info {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.menu .profile-info > * {
		margin: 0;
		line-height: 1.25;
	}
	.actions {
		display: flex;
		gap: 0.5rem;
		align-items: flex-end;
		padding-top: 0.5rem;
	}
	.profile-image {
		background-color: var(--accent-color, var(--purewhite-a90, black));
		background-position: center;
		background-size: cover;
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1rem;
		display: flex;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		position: relative;
	}

	.profile-button .profile-image:before {
		content: '';
		background-color: var(--warmgray);
		width: 100%;
		height: 100%;
		border-radius: 100%;
		transition:
			transform 0.3s ease-in-out 0.1s,
			border-radius 0.1s ease-in-out;
		transform: scale(0);
	}
	.profile-button .profile-image:after {
		--arrow-size: 0.25rem;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 var(--arrow-size) 0 var(--arrow-size);
		border-color: var(--white) transparent transparent transparent;
		position: absolute;
		top: calc(50% - var(--arrow-size) / 2);
		left: calc(50% - var(--arrow-size));
		transition:
			border-width 0.1s ease-in-out,
			transform 0.2s ease-in-out;
		transform: rotate(-90deg);
	}

	.profile-button .profile-image.open:before {
		transform: scale(0.5);
		transition: transform 0.2s ease-in-out;
	}
	.profile-button .profile-image.open:after {
		border-width: var(--arrow-size) var(--arrow-size) 0 var(--arrow-size);
		transform: rotate(0deg);
		transition:
			border-width 0.1s ease-in-out,
			transform 0.2s ease-in-out 0.2s;
	}
	@media (hover: hover) and (pointer: fine) {
		.profile-button .profile-image:hover:before {
			transform: scale(0.5);
			transition: transform 0.2s ease-in-out;
		}
		.profile-button .profile-image:hover:after {
			border-width: var(--arrow-size) var(--arrow-size) 0 var(--arrow-size);
			transform: rotate(0deg);
			transition:
				border-width 0.1s ease-in-out,
				transform 0.2s ease-in-out 0.2s;
		}
	}
	.profile-button .profile-image.open:before {
		transform: scale(1);
		border-radius: 0;
	}
	.profile-button .profile-image span:not(:focus):not(:active) {
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 0.0625rem;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 0.0625rem;
	}
</style>
