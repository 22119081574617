<script lang="ts">
	import DottyLogoMark from './DottyLogoMark.svelte';

	// import { page } from '$app/stores';
	import { onMount } from 'svelte';
	import ProfileMenu from './ProfileMenu.svelte';
	import MediaQuery from '../helpers/MediaQuery.svelte';

	const page = {
		'subscribe': () => {}, 
		'set': () => {}, 
		'update': () => {}, 
		'updateStart': () => {}, 
		'updateError': () => {}, 
		'updateComplete': () => {}, 
		'setOptions': () => {}, 
		'options': {}, 
		'url': {
			'pathname': '/'
			
		}
	}
	export let username: string = ""
	export let useremail: string = ""
	export let userpictureurl: string = ""
	export let isstaff: string = ""
	export let issuperuser: string = ""
	export let path: string = "";

	type UserInfo = {
		name: string;
		email: string;
		pictureUrl: string;
		isSuperuser: string;
		isStaff: string;
	};

	export let user : UserInfo = username === "" ? null :{
		name: username,
		email: useremail,
		pictureUrl: userpictureurl,
		isSuperuser: issuperuser,
		isStaff: isstaff
	}

	let open = false

	let blur =
		'brightness(1) blur(0.25rem) sepia(1) hue-rotate(-245deg) saturate(.75) contrast(1.25)';
	let background = 'var(--purewhite-a90)';

	function clickOutside(event){
		// set the open state of the menu to false when a click is detected anywhere outside the menu
		open = false
	}

	onMount(() => {
		window.addEventListener('click', clickOutside)
		return () => {
			window.removeEventListener('click', clickOutside)
		}
	})


</script>

<MediaQuery query="(max-width: 768px)" let:matches>
	<header
		style:background-color={matches ? background : 'transparent'}
		style:backdrop-filter={matches ? blur : ''}
		class:matches
	>
		<nav class="app-nav">
			<ul
				style:background-color={!matches ? background : 'transparent'}
				style:backdrop-filter={!matches ? blur : ''}
				style:-webkit-backdrop-filter={!matches ? blur : ''}
			>
				<li class="home" class:active={path === '/'}>
					<a href="/">
						<DottyLogoMark iconSize="1.5rem" />
						<span style="margin-left: 0.25rem;">Dotty</span>
					</a>
				</li>
				<li class:active={path.match(/\/experiment.*/)}>
					<a href="/experiment">Experiment</a>
				</li>
				{#if user && ( user.isSuperuser==="True" || !['Anonymous User','Prolific.co User'].includes(user.name))}
					<li class:active={path === '/data'}>
						<a href="/data">Data</a>
					</li>
				{/if}
			</ul>
			{#if !matches}
				<div
					class="angle"
					style="
					clip-path: url(#angleClip); 
					-webkit-clip-path: url(#angleClip);"
					style:background-color={background}
					style:backdrop-filter={blur}
					style:-webkit-backdrop-filter={blur}
				/>
			{/if}
		</nav>
		<nav>
			{#if !matches}
				<div
					class="angle flipped"
					style="clip-path: url(#angleClip); -webkit-clip-path: url(#angleClip); will-change: transform;"
					style:background-color={background}
					style:backdrop-filter={blur}
					style:-webkit-backdrop-filter={blur}
				/>
			{/if}
			<ul
				style:background-color={!matches ? background : 'transparent'}
				style:backdrop-filter={!matches ? blur : ''}
				style:-webkit-backdrop-filter={!matches ? blur : ''}
			>
				<li style:margin="0 1rem">
					{#if user}
						<ProfileMenu user={user} bind:open={open}/>
						<!-- <a href="/log-out">Log out</a> -->
					{:else}
						<a href="/log-in">Log in</a>
					{/if}
				</li>
			</ul>
		</nav>
	</header>
</MediaQuery>

<!-- Header angled background graphic -->
<svg class="angle-definition">
	<defs>
		<clipPath id="angleClip">
			<path d="M0 0H67.0003L26.6092 51.7006C20.5447 59.4632 11.243 64 1.39237 64H0V0Z" />
		</clipPath>
	</defs>
</svg>

<style>
	header {
		--background: var(--purewhite-a90);
		display: flex;
		justify-content: space-between;
		position: fixed;
		width: 100vw;
		z-index: 10;
		pointer-events: none;
	}
	.home {
		margin-left: 1rem;
	}

	.matches .home {
		margin-left: 0;
	}
	.home a {
		font-size: 1rem;
	}

	nav {
		display: flex;
		justify-content: center;
		position: relative;
		pointer-events: auto;
	}

	svg.angle-definition {
		height: 0;
		display: block;
		pointer-events: none;
	}
	.angle {
		height: 4rem;
		width: 4.1875rem;
		transform: translateY(0);
		position: relative;
		left: -0.01rem;
	}
	.angle.flipped {
		transform: translateY(0) scaleX(-1);
		left: 0.01rem;
	}
	ul {
		position: relative;
		padding: 0;
		margin: 0;
		height: 4em;
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		background: var(--background);
		background-size: contain;
		transition: 0.4s ease-in-out;
	}

	li {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	li:not(.home):after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 0.125rem;
		background-color: var(--orange);
		transform: scaleX(0);
		transition: transform 0.4s ease-in-out;
	}

	li.active:not(.home) {
		pointer-events: none;
	}

	li.active:not(.home):after {
		transform: scaleX(1);
	}

	.app-nav li a {
		z-index: 1;
	}
	nav a {
		display: flex;
		gap: 0.25rem;
		height: 2.5rem;
		align-items: center;
		padding: 0 1em;
		font-weight: 600;
		font-size: 0.8rem;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		text-decoration: none;
		transition: 0.4s ease-out;
		color: var(--black);
	}
	/* prevent link hovers on mobile needs to be fixed in global styles using the @media method below */
	a:hover {
		color: var(--black);
	}

	@media (hover: hover) and (pointer: fine) {
		a:hover {
			color: var(--orange);
		}
		.home a:hover svg.logo * {
			fill: var(--orange);
		}
	}
</style>
